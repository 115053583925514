import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Templates/Layout";
import SEO from "../utils/SEO";

// Markup
const FourOhFourPage = (props) => {
  const { data } = props;

  let doc;
  if (data) {
    doc = JSON.parse(data.index.content);
  }
  const openGraph = {
    type: "website",
    title: doc.name,
    description: doc.headline,
  };

  return (
    <Layout>
      <SEO openGraph={openGraph} />
      <section>
        <div className="container py-5">
          <div className="alert alert-danger text-center" role="alert">
            Sorry, the page you have requested could not be found
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FourOhFourPage;

export const QUERY = graphql`
  query FourOhFourPageQuery {
    index: storyblokEntry(slug: { eq: "rebelracingrivals" }) {
      content
    }
  }
`;
